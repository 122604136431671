// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventkalender-index-js": () => import("./../src/pages/adventkalender/index.js" /* webpackChunkName: "component---src-pages-adventkalender-index-js" */),
  "component---src-pages-adventkalender-teilnahmebedingungen-js": () => import("./../src/pages/adventkalender/teilnahmebedingungen.js" /* webpackChunkName: "component---src-pages-adventkalender-teilnahmebedingungen-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-adventkalender-empty-js": () => import("./../src/pages/info/adventkalender/empty.js" /* webpackChunkName: "component---src-pages-info-adventkalender-empty-js" */),
  "component---src-pages-info-mjamgreen-empty-js": () => import("./../src/pages/info/mjamgreen/empty.js" /* webpackChunkName: "component---src-pages-info-mjamgreen-empty-js" */),
  "component---src-pages-mjamgreen-index-js": () => import("./../src/pages/mjamgreen/index.js" /* webpackChunkName: "component---src-pages-mjamgreen-index-js" */)
}

